import Moment from 'moment';
import moment from 'moment-timezone';

export const getRoleId = (r) => {
    if(r === 'Member') {
      return "U";
    }
    return "A";
  }

export const getRoleName = (r) => {
    if(r === 'U') {
      return "Member";
    }
    return "Admin";
}

export default getRoleName

export const getDayOfMonth = (date) => {
  return parseInt(moment(date, 'YYYY-MM-DD').format('DD'))
}

export const isBeforeToday = (date1) => {
  console.log('isBeforeDate', date1);
  return Moment(date1).isBefore(Date())
}

export const isBeforeDate = (date1, date2) => {
  console.log('isBeforeDate', date1, date2);
  return Moment(date1).isBefore(date2)
}

export const dateToShowFormat = (value, zone) => {
  try{
    // Moment.locale('en');
    console.log('dateToShowFormat', value, zone);
    return Moment(value).local().format('dddd, DD MMM YYYY hh:mm A');
  }catch (e) {
    console.log(e);
  }
  return value;
} 

export const dateToTimestamp = (value, zone) => {
  try{
    // Moment.locale('en');
    console.log('dateToShowFormat', value, zone);
    return Moment(value).local().valueOf()
  }catch (e) {
    console.log(e);
  }
  return 0;
} 

export const dateToServerFormat = (date, time) => {

  const dt = `${date}T${time}`;
  const utcDate = Moment(dt).utc().format('YYYY-MM-DD')
  const utcTime = Moment(dt).utc().format('HH:mm:ss')

  console.log('utc', dt, utcDate, utcTime);

  return `${utcDate}T${utcTime}Z`;
} 

export function getTimeZone(){
  return moment.tz.guess();
}

export const getDateTimeNow = () => {
    // const now = Moment().format("YYYY-MM-DDTHH:mm:ss");
    // console.log('now', now);
    return moment.utc().format("YYYY-MM-DDTHH:mm:ss");
}

export const getTimeNow = () => {
  
  let date = Moment()
    .add(2, 'minutes')

  return date.format("HH:mm");

}

export const getDateNow = () => {
  
  let date = Moment()
    .add(2, 'minutes')

  return date.format("YYYY-MM-DD");

}

export const getDateAfterMonth = (count) => {
  
  let date = Moment()
    .add(count, 'month')

  return date.format("YYYY-MM-DD");

}